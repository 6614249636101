<template>
    <o-data-lookup :data-object="dschecklistLookupDO" 
                    :whereClause="`'${props.IdPath?props.IdPath:context.idPath}' LIKE IdPath + '%' AND ActivityGroup_ID = ISNULL(${(props.ActivityGroupID?props.ActivityGroupID:null)}, ActivityGroup_ID)`"
                    reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is && !readOnly" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <component v-if="is && readOnly" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue" readonly></component>

            <input v-else-if="textInput && !readOnly" :ref="target" v-bind="$attrs" :value="textInputValue">
            <input v-else-if="textInput && readOnly" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>

            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="200"></o-column>
        <o-column field="Title" width="300"></o-column>
        <o-column field="ActivityGroup" width="200"></o-column>
        <o-column field="OrgUnit" width="200"></o-column>
        <o-column field="Discipline" width="250"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { getOrCreateDataObject } from 'o365.vue.ts'
    import context from "o365.modules.Context.ts";

    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean,
        ActivityGroupID: Number,
        readOnly: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    const dschecklistLookupDO = getOrCreateDataObject({
        id: 'dschecklistLookupDO' + crypto.randomUUID(),
        viewName: 'aviw_Check_ChecklistsLookupIdPath',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string" , sortOrder: 1, sortDirection: "asc" },             
             {name: "Title", type: "string" },      
             {name: "ActivityGroup", type: "string" },
             {name: "OrgUnit", type: "string" },
             {name: "Discipline", type: "string"},
             {name: "Revision", type: "number"},
             {name: "Procedure", type: "string"},
             {name: "Discipline_ID", type: "number"},
             {name: "CompletionPhase", type: "string"},
             {name: "CompletionPhase_ID", type: "number"},
             {name: "FrequencyNumberOfDays", type: "string"}]
    });     
 
</script>